<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-copy"></i> Template</h5>
                    <btn-refresh @click="atualizar()" v-if="acao === 'DETALHAR' || acao === 'ATUALIZAR'"></btn-refresh>
                </div>
                <erros-box :erros="erros"></erros-box>
                <div class="p-formgrid p-grid p-fluid">
                    <div class="p-field p-col-12 p-md-4">
                        <label>Código</label>
                        <InputText type="text" v-model="codigo" :disabled="desabilitaForm" @input="v$.codigo.$touch()" />
                        <small class="p-error" v-if="v$.codigo.$error">O código é obrigatório</small>
                    </div>
                    <div class="p-field p-col-12 p-md-8">
                        <label>Descrição</label>
                        <InputText type="text" v-model="descricao" :disabled="desabilitaForm" @input="v$.descricao.$touch()" />
                        <small class="p-error" v-if="v$.descricao.$error">A descrição é obrigatória</small>
                    </div>
                    <div class="p-field p-col-12">
                        <label>Dados</label>
                        <Textarea v-model="dados" rows="5" @input="v$.dados.$touch()" />
                        <small class="p-error" v-if="v$.dados.$error">Os dados são obrigatórios</small>
                    </div>
                    <div class="p-field p-col-12">
                        <label>Arquivo</label>
                        <FileUpload :customUpload="true" @uploader="arquivoSelecionado" mode="basic" chooseLabel="Selecionar Arquivo" :auto="true" v-if="!arquivo" />
                        <div v-if="arquivo">
                            <strong>{{ nomeArquivo }}</strong>
                            <i class="pi pi-trash p-error icon-button p-ml-2" style="fontSize: 1.2rem" @click="limparArquivo()"></i><btn-download :template="template" v-if="acao == 'ATUALIZAR' && !arquivoAlterado"></btn-download>
                        </div>
                    </div>
                </div>
                <slot name="botoes"></slot>
                <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" v-if="!desabilitaForm" />
                <Button label="Salvar" icon="pi pi-check-circle" class="p-ml-2 p-button-primary" @click="confirmarSalvar()" v-if="!desabilitaForm" :disabled="v$.$invalid" />
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import BtnDownload from './BtnDownload';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    components: {
        BtnDownload,
    },

    props: {
        acao: {
            type: String,
        },

        template: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar', 'atualizar'],

    data() {
        return {
            codigo: null,
            descricao: null,
            dados: null,
            arquivo: null,
            nomeArquivo: null,
            arquivoAlterado: false,
        };
    },

    validations() {
        return {
            codigo: { required },
            descricao: { required },
            conteudo: {
                conteudoOK: () => {
                    return this.conteudoOK;
                },
            },
            dados: { required },
        };
    },

    methods: {
        preencher() {
            this.codigo = this.template?.codigo;
            this.descricao = this.template?.descricao;
            this.dados = this.template?.dados;
            if (this.template?.nomeArquivo) {
                this.nomeArquivo = this.template?.nomeArquivo;
                this.arquivo = {};
            }
            this.v$.$touch();
            this.arquivoAlterado = false;
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.acao.toLowerCase()} o template?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let templateDto = {
                codigo: this.codigo,
                descricao: this.descricao,
                dados: this.dados,
                arquivo: this.arquivo,
            };
            this.$emit('salvar', templateDto);
        },

        atualizar() {
            this.$emit('atualizar');
        },

        arquivoSelecionado(event) {
            this.arquivo = event.files[0];
            this.nomeArquivo = this.arquivo.name;
        },

        limparArquivo() {
            this.arquivo = null;
            this.arquivoAlterado = true;
        },
    },

    computed: {
        desabilitaForm() {
            return this.acao === 'DETALHAR';
        },

        conteudoOK() {
            return this.arquivo !== null;
        },
    },

    watch: {
        template() {
            this.preencher();
        },
    },
};
</script>
