<template>
    <font-awesome-icon
        icon="download"
        @click="download()"
        size="lg"
        class="icon-button p-ml-2 p-text-primary"
        title="Download"
        v-if="$temAcessoView('TEMPL-02')"
    />
</template>

<script>
import TemplatesService from './services';

export default {
    props: {
        template: {
            type: Object,
        }
    },

    methods: {
        download() {
            this.$store.dispatch('addRequest');
            TemplatesService.download(this.template.templateId).then(response => {
                if (response && response.success) {
                    this.$download(response.data, this.template.nomeArquivo);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Templates',
                        detail: 'Erro ao realizar download',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
